
@routeTransition : {
	fadeDuration : 0.2s;
}
@height : {
	navRow : 33px;
}
@swatches : {
	white : #fff;
	textWhite : darken( white, 8 );
	textBlack : lighten( black, 10 );
	awardColor : lighten( rgb(9, 121, 234), 20 );
	awardColor : darken(lightgreen, 30);
	shopBackground : lighten( black, 15 );
}
@border : {
	strongWidth : 3px;
}

@colGap : 0.75rem;

@screen__xs-width : 320px;
@screen__sm-width : 650px;
@screen__md-width : 1024px;
@screen__lg-width : 1500px;
@screen__xl-width : 1920px;	
	
@mediaQuery__xs   : ~"(max-width:" @screen__xs-width ~")";
@mediaQuery__sm   : ~"(max-width:" @screen__sm-width ~")";
@mediaQuery__md   : ~"(max-width:" @screen__md-width ~")";
@mediaQuery__dt   : (min-width: @screen__md-width);
@mediaQuery__lg   : ~"(min-width:" @screen__lg-width ~")";
@mediaQuery__xl   : ~"(min-width:" @screen__xl-width ~")";
